import React, { useState } from 'react'
import Link from '../utils/link'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Footer = () => {

  const [modal, setModal] = useState(false)
  const [complete, setComplete] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()
    // Send form data to Zapier
    const url = 'https://hooks.zapier.com/hooks/catch/2181815/3uo0du9/'
    const form = event.target
    const data = new FormData(form)
    const entries = data.entries()
    const formData = Object.fromEntries(entries)

    formData.url = window.location.pathname

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(formData),
    })
      .then(() => {
        setComplete(true)
      })
      .catch(error => console.log(error))
  }

  return (
    <>
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__left'>
            <ul>
              <li><Link to='+61398888899'>P 03 8657 2888</Link></li>
              <li><Link to='mailto:admin@ac-fs.com.au'>E admin@ac-fs.com.au</Link></li>
              <li><Link to='https://www.linkedin.com/company/associatedconcepts/about/'>Linkedin</Link></li>
            </ul>
            <p>Suite 4.03,Pacific Tower <br />
              737 Burwood Road<br />
              Hawthorn VIC 3122 Australia</p>
          </div>
          <div className='footer__right'>
            <ul>
            <p>© Associated Concepts 2023</p>
            <p><Link to='https://sharedfiles.atollon.au/AC-FS%20Financial%20Services%20Guide%20ver%2019.0.pdf'>Download Financial Services</Link></p>
            <p><Link to='https://sharedfiles.atollon.au/AC-FS%20Financial%20Services%20Guide%20ver%2019.0.pdf'>& Credit Guide</Link></p>

        {/*   <li><a href="#" onClick={(e) => {e.preventDefault(); setModal(true)}}>Download Financial Services & Credit Guide</a></li> */}
            </ul>
            <div>
              <p>Australian Financial Services Licence No. 240526</p>
              <p>Australian Credit Licence No. 240526</p>
            </div>
          </div>
        </div>
      </footer>
      <div className={`popup-modal ${modal ? 'active' : ''}`}>
        <div className="popup-modal__overlay" onClick={() => setModal(false)} />
        <div className="popup-modal__inner">
          <div className="popup-modal__content">
            <h2>Download Financial Services Guide</h2>
            { complete ? (
              <a href="https://sharedfiles.atollon.au/AC-FS%20Financial%20Services%20Guide%20ver%2019.0.pdf" download target="_blank" rel="noopener noreferrer">Click here to download</a>
            ) : (
              <form name='contact-form' onSubmit={onSubmit}>
                <div className='form__row form__row--double'>
                  <div className='form__column'>
                    <input className='form__input' type='text' name='fullname' id='fullname-field' placeholder='Full Name' required />
                  </div>
                  <div className='form__column'>
                    <input className='form__input' type='text' name='email' id='email-field' placeholder='Contact Email' required />
                  </div>
                </div>
                <div className='form__row form__row--submit'>
                  <button type='submit'>Download</button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
